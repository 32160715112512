<template>
  <a-modal
    title="处理上访"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="dealStatusApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="上访人姓名"
      >
        {{ queryParam.visitorName }}
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="联系电话"
      >
        {{ queryParam.contactPhone }}
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="上访原因"
      >
        {{ queryParam.visitReason }}
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="处理结果"
        :validateStatus="checkParams.missionStatusStatus"
        :help="checkParams.missionStatusStatusMsg"
      >
        <a-textarea
          style="width: 90%;"
          :rows="3"
          v-model="queryParam.description"
          placeholder="备注"
          @change="changeStatus"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { addDealShangFangRecord } from '@/api/shangfang'
export default {
  name: 'DealShangfang',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      missionStatus: 0,
      checkParams: {
        missionStatusStatus: 'success',
        missionStatusStatusMsg: null
      },
      queryParam: {
        shangFangKeyId: 0,
        shangFangId: '',
        description: '',
        visitorName: '',
        contactPhone: '',
        visitReason: ''
      },
      statusLists: []
    }
  },
  created () {
  },
  methods: {
    ininData (data) {
      console.log('ininData', data)
      this.checkParams.missionStatusStatus = 'success'
      this.checkParams.missionStatusStatusMsg = null
      this.queryParam.shangFangKeyId = data.keyId
      this.queryParam.shangFangId = data.id
      this.queryParam.visitorName = data.visitorName
      this.queryParam.contactPhone = data.contactPhone
      this.queryParam.visitReason = data.visitReason
      this.queryParam.description = ''
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    dealStatusApi () {
      const _this = this
      if (_this.queryParam.description.trim() === '') {
        _this.checkParams.missionStatusStatus = 'error'
        _this.checkParams.missionStatusStatusMsg = '请输入处理结果'
        return false
      } else {
        _this.checkParams.missionStatusStatus = 'success'
        _this.checkParams.missionStatusStatusMsg = null
      }
      addDealShangFangRecord(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$emit('ok', 1)
          _this.cancelForm()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeStatus () {
      if (this.queryParam.description.trim() === '') {
        this.checkParams.missionStatusStatus = 'error'
        this.checkParams.missionStatusStatusMsg = '请输入处理结果'
      } else {
        this.checkParams.missionStatusStatus = 'success'
        this.checkParams.missionStatusStatusMsg = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
